<template>
<div class="page">
  <PageTitle title="首页活动banner部署" :border="false"></PageTitle>
  <div class="fix-head">
    <p class="tips">活动入口，位置在界面首屏处，专属活动曝光位</p>
  </div>
  <div class="content flex">
    <div class="edit">
      <el-form :model="edit" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px" label-position="right">
        <el-form-item label="添加banner：" prop="imgurl">
          <Upload id="banner" src="activity" @success="onUpload">
            <div class="logo" v-if="edit.imgurl">
              <img :src="edit.imgurl" alt="img" width="100%">
            </div>
            <div class="upload-box flex-center" v-else>
              <i class="el-icon-plus"></i>
              <span class="text">点击上传</span>
            </div>
          </Upload>
          <span class="tips">建议图片尺寸1030x270像素，且大小 &lt;=300KB，支持jpg、png、gif格式</span>
        </el-form-item>
        <el-form-item label="起止日期：" prop="startend">
          <el-date-picker
            v-model="edit.startend"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item label="跳转类型：" prop="jump_type">
          <el-select v-model="edit.jump_type" placeholder="请选择">
            <el-option
              v-for="item in jumpOptions"
              :key="item.type"
              :label="item.name"
              :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转参数：">
          <el-input v-model="edit.external_params"></el-input>
        </el-form-item>
        <el-form-item label="活动状态：">
          <el-radio-group v-model="edit.status">
            <el-radio :label="1">生效</el-radio>
            <el-radio :label="0">未生效</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onConfirm">确定</el-button>
          <!-- <el-button @click="onCancel">取消</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="demo">
      <h3 class="title">首页活动banner示例</h3>
      <img src="https://image.topboom0912.com/admin_static_img/demo/index_active_banner.png" alt="demo" width="298px">
    </div>
  </div>
</div>
</template>

<script>
import {mapState} from 'vuex'
import PageTitle from '@/components/PageTitle'
import Upload from '@/components/Upload'
export default {
  name: 'HomeActiveBanner',
  components: {
    PageTitle,
    Upload
  },
  data() {
    return {
      edit: {
        img: {
          w: 100,
          h: 100,
          url: ''
        },
        imgurl: '',
        startend: null,
        jump_type: '',
        external_params: '',
        status: 1
      },
      rules: {
        name: [{required: true, message: '请输入名称'}],
        jump_type: [{required: true, message: '请选择跳转类型'}],
        startend: [{required: true, message: '请选择日期'}],
        imgurl: [{required: true, message: '请上传banner'}]
      }
    }
  },
  computed: mapState([
    'jumpOptions'
  ]),
  async mounted() {
    const data = await this.getList()
    if (!data) return
    this.edit = data
  },
  methods: {
    getList() {
      const params = {
        page_size: 10,
        page_no: 0,
        position_type: 3
      }
      const url = 'admin/activity/list'
      return new Promise(resolve => {
        this.$https.get(url, {params}).then(res => {
          if (res.list) {
            const item = res.list[0]
            item.imgurl = this.$img(item.img)
            item.img = JSON.parse(item.img)
            item.startend = [item.start_time, item.end_time]
            resolve(item)
          } else {
            resolve(false)
          }
        })
      })
    },
    onUpload(url) {
      this.edit.imgurl = url
    },
    async onConfirm () {
      const rule = await new Promise(resolve => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid)
        })
      })
      if (!rule) return
      const params = JSON.parse(JSON.stringify(this.edit))
      if (params.imgurl !== params.img.url) params.img.url = params.imgurl
      params.img = JSON.stringify(params.img)
      params.start_time = params.startend[0]
      params.end_time = params.startend[1]
      delete params.imgurl
      delete params.startend
      const url = 'admin/activity/edit'
      const data = await this.$https.post(url, params)
      if (data) this.$message.success('保存成功')
    }
    // onCancel() {
    // }
  }
}
</script>

<style scoped lang="scss">
.fix-head {
  .tips {
    padding-bottom: 16px;
    margin: 0;
    color:rgba(0, 0, 0, 0.65)
  }
}
.edit {
  padding-right: 70px;
  border-right: 1px solid #E8E8E8;
  box-sizing: border-box;
  .tb-form {
    width: 640px;
    .tips {
      padding-top: 8px;
      color: rgba(0, 0, 0, 0.45);
    }
    .demo-text {
      color: #1890FF;
      cursor: pointer;
    }
    .upload {
      display: flex;
      flex-direction: column;
      line-height: 1;
      &-box {
        width: 343px;
        height: 90px;
        flex-direction: column;
        border-radius: 4px;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        cursor: pointer;
        .text {
          padding-top: 8px;
          color: rgba(0, 0, 0, 0.65);
        }
      }
      .logo {
        position: relative;
        width: 343px;
        height: 90px;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}
.demo {
  padding-left: 70px;
  .title {
    margin: 0;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
